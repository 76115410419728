import React from "react";

import hwtiStyles from "./headerWithTextAndImage.module.css";

const headerWithTextAndImage = props => {
  return (
    <section
      className={hwtiStyles.hwtiSection}
      style={
        props.lightBackground === true
          ? props.noPadding === true
            ? { backgroundColor: "#fff", padding: "0px 20px" }
            : { backgroundColor: "#fff" }
          : props.noPadding === true
          ? { padding: "0px" }
          : null
      }
    >
      {/* {props.paragraphHeader !== false ? (
        <div>
          {" "}
          <h2>{props.paragraphHeader}</h2>
        </div>
      ) : null} */}
      <div className={hwtiStyles.hwtiWrapper}>
        <div className={hwtiStyles.copyContainer}>
          {props.paragraphHeader !== false ? (
            <div>
              {" "}
              <h2>{props.paragraphHeader}</h2>
            </div>
          ) : null}
          <div className={hwtiStyles.hwtiCopy}>{props.children}</div>
        </div>
        <div className={hwtiStyles.hwtiImgContainer}>
          <img
            style={props.offsetImg === true ? { marginTop: "20px" } : null}
            src={props.imgRight}
          ></img>
        </div>
      </div>
    </section>
  );
};
export default headerWithTextAndImage;
