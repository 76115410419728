import React from "react";

import headlineStyles from "./headline.module.css";

const headline = (props) => {
  return (
    <div className={headlineStyles.headline} style={props.lightBackground === true?{backgroundColor:"#fff"}:null}>
      <h2>
        {props.title}
      </h2>
    </div>
  );
};
export default headline;
