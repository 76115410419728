import React from "react";
import { Link } from "gatsby";

import janusStyles from "./janusHypothesis.module.css";

import Headline from "../../../components/headline";
import HeaderWithTextAndImage from "../../../components/headerWithTextAndImage";






import Head from "../../../components/head";



import {graphql} from "gatsby";

export const query = graphql`query{
  allContentfulJanusHypothesisPage{
    edges{
      node{
        section1Headline{
          section1Headline
        }
        section1Paragraph{
          section1Paragraph
        }
        section1Image{
          file{
            url
          }
        }
        ecologyImage{
          file{
            url
          }
        }
        section2Headline{
          section2Headline
        }
        section2Paragraph{
          section2Paragraph
        }
        section2Image{
          file{
            url
          }
        }
        section3Headline{
          section3Headline
        }
        section3Paragraph{
          section3Paragraph
        }
        section3Image{
          file{
            url
          }
        }
        musicEquationImage{
          file{
            url
          }
        }
        section4Image{
          file{
            url
          }
        }
        section4Headline{
          section4Headline
        }
        section4Paragraph{
          section4Paragraph
        }
        section5Paragraph{
          section5Paragraph
        }
        section6Image{
          file{url}
        }
        section6Paragraph{
          section6Paragraph
        }
      }
    }
  }
}`;


const JanusHypothesis = (props) => {
  return (
    <>
    <Head title="Janus Hypothesis"/>
    <Headline title={props.data.allContentfulJanusHypothesisPage.edges[0].node.section1Headline.section1Headline} lightBackground={true}></Headline>
    <section   className={janusStyles.ourResearchSection}  style={{backgroundColor:"#fff"}}>
        <div>
          <div>
            <img src={props.data.allContentfulJanusHypothesisPage.edges[0].node.section1Image.file.url} />

            <p style={{whiteSpace:"pre-wrap"}}>
            {props.data.allContentfulJanusHypothesisPage.edges[0].node.section1Paragraph.section1Paragraph} </p>
          </div>
        </div>
      </section>

     
      <section className={janusStyles.howJanusWorks}  style={{backgroundColor:"#fff"}}>
        <div>
        <img src={props.data.allContentfulJanusHypothesisPage.edges[0].node.ecologyImage.file.url} alt="janus diagram" />
        </div>
      </section>
      <section style={{backgroundColor:"#fff"}} className={janusStyles.ourResearchSection}>
        <div>
          <h2>{props.data.allContentfulJanusHypothesisPage.edges[0].node.section2Headline.section2Headline}</h2>
          <div>
            <img src={props.data.allContentfulJanusHypothesisPage.edges[0].node.section2Image.file.url} />
          
            <p style={{whiteSpace:"pre-wrap"}}>
             {props.data.allContentfulJanusHypothesisPage.edges[0].node.section2Paragraph.section2Paragraph}
            </p>
          </div>
        </div>
      </section>
      <HeaderWithTextAndImage
        paragraphHeader= {props.data.allContentfulJanusHypothesisPage.edges[0].node.section3Headline.section3Headline}
        imgRight={props.data.allContentfulJanusHypothesisPage.edges[0].node.section3Image.file.url}
        isFirstElement={false}
        lightBackground={true}
      ><p style={{whiteSpace:"pre-wrap"}}>
      {props.data.allContentfulJanusHypothesisPage.edges[0].node.section3Paragraph.section3Paragraph}
    </p></HeaderWithTextAndImage>
      
      <section style={{backgroundColor:"#fff"}} className={janusStyles.howJanusWorks}>
        <div>
        <img src={props.data.allContentfulJanusHypothesisPage.edges[0].node.musicEquationImage.file.url} alt="janus diagram" />
        </div>
        
      </section>
      <section  className={janusStyles.ourResearchSection}  style={{backgroundColor:"#fff"}}>
        <div>
          <h2>{props.data.allContentfulJanusHypothesisPage.edges[0].node.section4Headline.section4Headline}</h2>
          <div>
            <img src={props.data.allContentfulJanusHypothesisPage.edges[0].node.section4Image.file.url} />

            <p style={{whiteSpace:"pre-wrap"}}>
      {props.data.allContentfulJanusHypothesisPage.edges[0].node.section4Paragraph.section4Paragraph}
    </p></div>
        </div>
      </section>
      
      
      <div className={janusStyles.separator}>
        <div />
      </div>
      <section className={janusStyles.researchBackground} style={{backgroundColor:"#fff"}}>
        <div>
          
              <p>
               {props.data.allContentfulJanusHypothesisPage.edges[0].node.section5Paragraph.section5Paragraph}
                </p>
              </div>
            
      </section>
      <section className={janusStyles.ourResearchSection}  style={{backgroundColor:"#fff"}}>
        <div>
          <div>
            <img src={props.data.allContentfulJanusHypothesisPage.edges[0].node.section6Image.file.url} />

            <p style={{whiteSpace:"pre-wrap"}}>
              {props.data.allContentfulJanusHypothesisPage.edges[0].node.section6Paragraph.section6Paragraph}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Link
                to="/donate"
                style={{
                  textDecoration: "none",
                  color: "#ba0c2f",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                Support our research of proving ‘Better Music, Better world’{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default JanusHypothesis;
